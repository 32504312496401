<script>
import { getBalance, thirdPartyBalanceTransfer, thirdPartyBalanceTransferV1, thirdPartyBalanceFetch, thirdPartyBalanceFetchV1 } from "@/api/my";
import { getThirdPartyList } from "@/api/home";
export default {
    name:'Exchange',
    components:{
        Header: () => import('components/base/Header'),

        'mt-header':app.Mint.Header,
        'mt-button':app.Mint.Button,
        'mt-actionsheet':app.Mint.Actionsheet,
    },
    data() {
        return {
            unfinish: false,
            user_money: "",
            change_money: "",

            sheetVisibleIn: false,
            sheetVisibleOut: false,
            datalistOut: {},
            datalistshowOut: [],
            datalistIn: {},
            datalistshowIn: [],
            thirdParty:[],

            header:{
                mainTitle: getWord('transfer_client_by_others'),
                headerMenu:false,
                backButton:false
            },
            transfering:false,
            selfTag:false,
        };
    },
    methods: {

        fetchBalance(type){
            var that=this;
            function toggle(type){
                var result = that.thirdParty.filter(item=>{
                    return item.name===type
                })
                if (result.length===1) {
                    return result[0]
                }else {
                    return {}
                }
            }            
            toggle(type).fetching=true;        
            if (type==='JdbCasino'||type==='SboCasino'||type==='SaCasino') {
                thirdPartyBalanceFetchV1(
                        type.slice(0,type.indexOf('Casino')),
                    ).then(result=>{
                    if (result.data.code == "SUCCESS") {
                        toggle(type).fetching=false;
                        toggle(type).balance=Number(result.data.result.money).toFixed(2)
                        app.Mint.Toast(getWord('balance_obtain_success'))
                    }                    
                    else {
                        toggle(type).fetching=false;
                        app.Mint.Toast(getWord('balance_obtain_failure'))
                    }
                })
            }else {
                thirdPartyBalanceFetch( '/main/'+type+'/getMoney' ).then(result=>{
                    if (result.data.code == "SUCCESS") {
                        toggle(type).fetching=false;
                        toggle(type).balance=Number(result.data.result.money).toFixed(2)
                        app.Mint.Toast(getWord('balance_obtain_success'))
                    }                    
                    else {
                        toggle(type).fetching=false;
                        app.Mint.Toast(getWord('balance_obtain_failure'))
                    }
                })
            }
        },
        AccountTypeIn: function() {
            this.sheetVisibleIn = true;
        },
        AccountTypeOut: function() {
            this.sheetVisibleOut = true;
        },
        //下拉函数
        getresultIn: function(val, inx) {
            this.datalistIn = val;
            if (this.datalistOut.id!=='self') {
                this.datalistOut = this.datalistshowOut[0];
            }
        },
        getresultOut: function(val, inx) {
            this.datalistOut = val;
            if (this.datalistIn.id!=='self') {
                this.datalistIn = this.datalistshowIn[0];
            }
        },
        left_click() {
            let hisNum = parseInt(window.history.length);
            if (hisNum == 1) {
                this.$router.push("/home");
            } else {
                this.$router.go(-1);
            }
        },
        //提交转换
        getmonny() {
            if (this.unfinish) {
                if (this._TEMPLATE!=='template-3') {
                    app.Mint.Toast(getWord('be_patient'));
                }
                return false;
            }

            var reg = /^[0-9]+.?[0-9]*$/;
            if (!reg.test(this.change_money)) {
                app.Mint.Toast(getWord('transfer_rules2'));
                return;
            } else if (Number(this.change_money) <= 0) {
                app.Mint.Toast(getWord('transfer_rules3'));
                return;
            }

            this.unfinish=true;

            if (this.datalistIn.id==='SaCasino' || this.datalistOut.id==='SaCasino' || this.datalistIn.id==='JdbCasino' || this.datalistOut.id==='JdbCasino' || this.datalistIn.id==='SboCasino' || this.datalistOut.id==='SboCasino') {

                thirdPartyBalanceTransferV1(
                    this.datalistIn.id!=='self'
                        ?(this.datalistIn.id.slice(0,this.datalistIn.id.indexOf('Casino')))
                        :(this.datalistOut.id.slice(0,this.datalistOut.id.indexOf('Casino'))), 
                    this.datalistIn.id!=='self'
                        ?1
                        :2, 
                    this.change_money, 
                ).then(result=>{
                    if (result.data.code == "SUCCESS") {
                        if (this._TEMPLATE==='template-3') {
                            this.transfering=false;
                            if (this.datalistIn.id!=='self') {
                                app.Mint.Toast(getWord(['transfer_in', 'success']));
                            }else {
                                app.Mint.Toast(getWord(['transfer_out', 'success']));
                            }
                        }
                        this.getThirdPartyBalance();
                        this.unfinish=false;
                    }else {
                        app.Mint.Toast(result.data.msg);
                        this.unfinish=false;
                    }
                })

            }else if (this.datalistIn.id==='asCasino' || this.datalistOut.id==='asCasino') {
                    
                thirdPartyBalanceTransfer( 
                    this.datalistIn.id!=='self'
                        ?0
                        :1, 
                    this.change_money, 
                    '/main/'+ (this.datalistIn.id!=='self'
                        ?this.datalistIn.id.charAt(0).toUpperCase() + this.datalistIn.id.slice(1)
                        :this.datalistOut.id.charAt(0).toUpperCase() + this.datalistOut.id.slice(1)) + '/moneyTransfer' 
                ).then(result=>{
                    if (result.data.code == "SUCCESS") {
                        if (this._TEMPLATE==='template-3') {
                            this.transfering=false;
                            if (this.datalistIn.id!=='self') {
                                app.Mint.Toast(getWord(['transfer_out', 'success']));
                            }else {
                                app.Mint.Toast(getWord(['transfer_in', 'success']));
                            }
                        }
                        this.getThirdPartyBalance();
                        this.unfinish=false;
                    }else {
                        app.Mint.Toast(result.data.msg);
                        this.unfinish=false;
                    }
                })

            }else {

                thirdPartyBalanceTransfer( 
                    this.datalistIn.id!=='self'
                        ?1
                        :2, 
                    this.change_money, 
                    '/main/'+ (this.datalistIn.id!=='self'
                        ?this.datalistIn.id.charAt(0).toUpperCase() + this.datalistIn.id.slice(1)
                        :this.datalistOut.id.charAt(0).toUpperCase() + this.datalistOut.id.slice(1)) + '/moneyTransfer' 
                ).then(result=>{
                    if (result.data.code == "SUCCESS") {
                        if (this._TEMPLATE==='template-3') {
                            this.transfering=false;
                            if (this.datalistIn.id!=='self') {
                                app.Mint.Toast(getWord(['transfer_in', 'success']));
                            }else {
                                app.Mint.Toast(getWord(['transfer_out', 'success']));
                            }
                        }
                        this.getThirdPartyBalance();
                        this.unfinish=false;
                    }else {
                        app.Mint.Toast(result.data.msg);
                        this.unfinish=false;
                    }
                })

            }

        },
        getThirdPartyBalance(){
            getBalance().then(result => {
                if (result.data.code == "SUCCESS") {
                    this.user_money = result.data.result.user_money;
                    this.thirdParty.forEach(item=>{
                        item.balance=Number(result.data.result[item.name.toLowerCase().split('casino')[0]+'_money']).toFixed(2)
                    })
                } else {
                    app.Mint.Toast(result.data.msg);
                }
            });
        },
        transferingPopup(data,selfTag){
            this.selfTag=selfTag;
            this.transfering=true;
            if (selfTag) {
                this.datalistIn.id=data.name
                this.datalistIn.name=data.remark

                this.datalistOut.id='self'
                this.datalistOut.name=getWord('main_account')
            }else {
                this.datalistIn.id='self'
                this.datalistIn.name=getWord('main_account')

                this.datalistOut.id=data.name
                this.datalistOut.name=data.remark
            }
        },

        fetchImagePath(item){
            if (_TEMPLATE==='template-3'&&item.name==='WmCasino') {
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/wm.png');
            }else if (_TEMPLATE==='template-3'&&item.name==='BgCasino') {
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/bg.png');
            }else if (_TEMPLATE==='template-3'&&item.name==='OgCasino') {
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/og.png');
            }else if (_TEMPLATE==='template-3'&&item.name==='AwcCasino') { 
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/awc.png');
            }else if (_TEMPLATE==='template-3'&&item.name==='BbinCasino') { 
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/bbin.png');
            }else if (_TEMPLATE==='template-3'&&item.name==='AgCasino') { 
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/ag.png');
            }else if (_TEMPLATE==='template-3'&&item.name==='sexyCasino') { 
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/sexy.png');
            }else if (_TEMPLATE==='template-3'&&item.name==='AgsCasino') { 
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/ags.png');
            }else if (_TEMPLATE==='template-3'&&item.name==='DgCasino') { 
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/dg.png');
            }else if (_TEMPLATE==='template-3'&&item.name==='KyCasino') { 
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/ky.png');
            }else if (_TEMPLATE==='template-3'&&item.name==='MgCasino') { 
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/mg.png');
            }else if (_TEMPLATE==='template-3'&&item.name==='GgCasino') { 
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/gg.png');
            }else if (_TEMPLATE==='template-3'&&item.name==='CqCasino') { 
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/cq.png');
            }else if (_TEMPLATE==='template-3'&&item.name==='CsCasino') { 
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/cs.png');
            }else if (_TEMPLATE==='template-3'&&item.name==='DsCasino') { 
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/ds.png');
            }else if (_TEMPLATE==='template-3'&&item.name==='KgCasino') { 
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/kg.png');
            }else if (_TEMPLATE==='template-3'&&item.name==='XaCasino') { 
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/xa.png');
            }else if (_TEMPLATE==='template-3'&&item.name==='LcCasino') { 
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/lc.png');
            }else if (item.name==="SbCasino") {
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/sb.png');
            }else if (item.name==="JdbCasino") {
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/jdb.png');
            }else if (item.name==="SboCasino") {
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/sbo.png');
            }else if (item.name==="SaCasino") {
                return require('@@/assets/'+_TEMPLATE+'/images/home/game-list/sa.png');
            }else {
                return item.url
            }
        },
    },
    mounted() {
        var that=this;
        this.getThirdPartyBalance()
        // this.CustomScroll(this.$refs.personal.id);
        getThirdPartyList().then(result => {
            if (result.data.code == "SUCCESS") {
                var data={
                    switchIn:[],
                    switchOut:[],
                };
                data.switchIn.push({ id: 'self', method: this.getresultIn, name: getWord('main_account2') })
                data.switchOut.push({ id: 'self', method: this.getresultOut, name: getWord('main_account2') })

                result.data.result.forEach(item=>{
                    this.thirdParty.push({
                        name:item.name,
                        remark:item.remark,
                        balance:null,
                        fetching:false,
                        url:item.img,
                    })
                    data.switchOut.push({
                        id: item.name,
                        method: this.getresultOut,
                        name: item.remark
                    })
                    data.switchIn.push({
                        id: item.name,
                        method: this.getresultIn,
                        name: item.remark
                    })
                })
                
                data.switchIn.forEach( (item,index)=>{
                    this.$set(this.datalistshowIn,index,data.switchIn[index])
                    
                })
                data.switchOut.forEach( (item,index)=>{
                    this.$set(this.datalistshowOut,index,data.switchOut[index])
                    
                })

                this.$set(this,'datalistOut',this.datalistshowIn[0])
                this.$set(this,'datalistIn',this.datalistshowIn[1]?this.datalistshowIn[1]:this.datalistshowIn[0])

                this.getThirdPartyBalance();
            }
        })
    },
    computed:{
        language(){
            return window.language
        }
    }
};
</script>
<template>
    <div id="personal" ref="personal" :class="_TEMPLATE">
        <template v-if="_TEMPLATE==='template-1'">
            <mt-header :title="getWord('transfer_client_by_others')" style="height:0.88rem;font-size: 0.35rem;">
                <mt-button icon="back" slot="left" @click="left_click()">{{ getWord('back_to_previous') }}</mt-button>
            </mt-header>
            <div class="mainText">{{ getWord('account_balance3') }}</div>
            <div class="header">
                <div class="headerMain">
                    <span class="headerTitle">{{ getWord('main_account2') }}</span>
                    <span class="headerMoney">{{user_money}}</span>
                </div>
                <ul class="wm">
                    <li v-for="item in thirdParty" @click="fetchBalance(item.name)">
                        <span class="headerTitle">{{item.remark}}</span>
                        <span class="headerMoney">{{item.balance}}</span>
                        <span class="refresh" :class="{spin:item.fetching}"></span>
                    </li>
                </ul>
            </div>
            <h6>{{ getWord('transfer_tips') }}</h6>
            <div class="mainText">{{ getWord('transfer_client_by_others') }}</div>
            <div class="hinder">
                <div class="hindertext">
                    <li @click="AccountTypeOut">
                        <span class="textLine">{{ getWord('transfer_out') }}</span>
                        <span class="money_text" v-if="datalistOut">{{datalistOut.name}}</span>
                        <img src="@@/assets/images2/user/moneychange/jt.png" class="rightArrow" />
                    </li>
                    <li @click="AccountTypeIn">
                        <span class="textLine">{{ getWord('transfer_in') }}</span>
                        <span class="money_text" v-if="datalistIn">{{datalistIn.name}}</span>
                        <img src="@@/assets/images2/user/moneychange/jt.png" class="rightArrow" />
                    </li>
                </div>
                <div class="hinderMoney">
                    <span class="hinderSpan">{{ getWord(['transfer_in', 'amount_of_betting']) }}({{ currencySymbol() }}）</span>
                    <span class="tip">￥</span>
                    <input type="number" :placeholder="getWord(['fill', 'amount_of_betting'])" v-model="change_money" class="mtField" @blur="_blured($event)">
                </div>
            </div>
            <mt-button class="hinderButton" type="primary" @click="getmonny()">{{ getWord(['start', 'transfer']) }}</mt-button>
        </template>
        <template v-if="_TEMPLATE==='template-2'">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
            <div class="mainText">{{ getWord('account_balance3') }}</div>
            <div class="header">
                <div class="headerMain clearfix">
                    <span class="headerTitle">QY-{{ getWord('main_account2') }}</span>
                    <span class="headerMoney">{{ currencySymbol() }} {{user_money}}</span>
                </div>
                <ul class="wm">
                    <li v-for="item in thirdParty" @click="fetchBalance(item.name)" class="clearfix">
                        <span class="headerTitle">{{item.remark}}</span>
                        <span class="headerMoney">{{item.balance}}</span>
                        <span class="refresh" :class="{spin:item.fetching}"></span>
                    </li>
                </ul>
            </div>
            
            <div class="mainText">{{ getWord(['select', 'transfer_condition']) }}</div>
            <div class="hinder">
                <div class="hindertext">
                    <li @click="AccountTypeOut">
                        <span class="textLine">{{ getWord('transfer_out') }}</span>
                        <span class="money_text" v-if="datalistOut">{{datalistOut.name}}</span>
                        <img src="@@/assets/images2/user/moneychange/jt.png" class="rightArrow" />
                    </li>
                    <li @click="AccountTypeIn">
                        <span class="textLine">{{ getWord('transfer_in') }}</span>
                        <span class="money_text" v-if="datalistIn">{{datalistIn.name}}</span>
                        <img src="@@/assets/images2/user/moneychange/jt.png" class="rightArrow" />
                    </li>
                </div>
                <div class="hinderMoney">
                    <input type="number" :placeholder="getWord(['fill', 'amount_of_betting'])" v-model="change_money" class="mtField" @blur="_blured($event)">
                </div>
            </div>
            <mt-button class="hinderButton" type="primary" @click="getmonny()">{{ getWord(['start', 'transfer']) }}</mt-button>
        </template>
        <template v-if="_TEMPLATE==='template-3'">
            <Header
                :mainTitle="header.mainTitle"
                :backButton=false
            />
            <div class="header">
                <div class="inner clearfix">
                    <h3>
                        {{ getWord('account_balance') }}
                        <span>{{ getWord('balance') }}:<b>{{user_money}}{{ currencySymbol() }}</b></span>
                    </h3>                    
                </div>
            </div>
            <ul class="wm">
                <li v-for="item in thirdParty" class="clearfix">
                    <template v-if="_TEMPLATE==='template-1'||_TEMPLATE==='template-2'">
                        <img :src="item.url" />
                    </template>
                    <template v-if="_TEMPLATE==='template-3'">
                        <img :src="fetchImagePath(item)" />
                    </template>
                    <content>
                        <div class="info" @click.stop="fetchBalance(item.name)">
                            <span class="headerTitle">
                                {{item.remark}}
                                <span class="refresh" :class="{spin:item.fetching}"></span>
                            </span>
                            <span class="headerMoney">{{ getWord('quota') }}: {{item.balance}}</span>                            
                        </div>
                        <div class="operations">
                            <a class="transfer-out" @click="transferingPopup(item,false)">{{ getWord('transfer_out') }}</a>
                            <a class="transfer-in" @click="transferingPopup(item,true)">{{ getWord('transfer_in') }}</a>
                        </div>
                    </content>
                </li>
            </ul>
            <aside class="transfering-popup" v-if="transfering">
                <div class="inner">
                    <h4>
                        <template v-if="!selfTag">
                            从{{datalistOut.id}} {{ getWord('transfer_in2') }}
                        </template>
                        <template v-else>
                            {{ getWord('transfer_out2') }}{{datalistIn.id}}
                        </template>
                    </h4>
                    <fieldset>
                        <label>
                            <template v-if="!selfTag">
                                {{ getWord(['transfer_out','amount_of_betting']) }}:
                            </template>
                            <template v-else>
                                {{ getWord(['transfer_in','amount_of_betting']) }}:
                            </template>
                        </label>
                        <input type="number" v-model="change_money" />
                    </fieldset>
                    <div class="buttons">
                        <a class="cancel" @click='transfering=false' v-if="!unfinish">{{ getWord('cancel') }}</a>
                        <a class="ok" @click="getmonny()" :class="{processing:unfinish}">
                            <template v-if="!unfinish">
                                {{ getWord('ok') }}
                            </template>
                            <template v-else>
                                {{ getWord('processing') }}
                            </template>
                        </a>
                    </div>
                </div>
            </aside>
        </template>
        <mt-actionsheet :actions="datalistshowIn" v-model="sheetVisibleIn"></mt-actionsheet>
        <mt-actionsheet :actions="datalistshowOut" v-model="sheetVisibleOut"></mt-actionsheet>
    </div>
</template>
<style lang="scss" scoped>
@import "@@/assets/style/_variables";
#personal {
    overflow: auto;
    padding-bottom: .5rem;

    &.template-3 {

        .header {
            background-color: #B0B0B0;
            padding:.2rem;
            box-sizing: border-box;

            .inner {
                background-color: #EEEEEE;
                text-align: center;

                h3 {
                    font-size: .3rem;
                    font-weight: normal;
                    color: #5F646E;
                    line-height: 1.6;
                    padding: .2rem 0;

                    span {
                        display: block;
                        font-size: .28rem;

                        b {
                            font-size: .36rem;
                            color: #EC2829;
                            font-weight: normal;
                        }
                    }
                }
            }
        }

        .wm {

            li {
                display: flex;
                padding: .25rem;
                padding-bottom: 0;
                min-height: 1.4rem;

                > img {
                    max-width: 1.08rem;
                    max-height: 1.08rem;
                }

                content {
                    display: flex;
                    position: relative;
                    flex-grow: 1;
                    justify-content: space-between;
                    border-bottom: 2px solid #B0B0B0;
                    padding-bottom: .25rem;
                    margin-left: .25rem;

                    .info {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;

                        .headerTitle {
                            font-size: .36rem;
                            display: block;
                        }

                        .headerMoney {
                            font-size: .28rem;
                        }

                        .refresh {
                            width: .32rem;
                            height: .32rem;
                            position: static;
                        }
                    }

                    .operations {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-around;

                        a {
                            font-size: .3rem;
                            display: block;
                            padding: 0.01rem .25rem;
                            color: #ffffff;
                            border-radius: 2px;

                            &.transfer-out {
                                background-color: #3C8EFB;
                            }

                            &.transfer-in {
                                background-color: #EC2829;
                            }
                        }
                    }
                }
            }
        }
    }

    &.template-2 {
        background-color: #F5F5F9;

        .mainText {
            color: #999999;
            padding: .4rem .5rem;
            height: auto;
            background-color: #ffffff;
        }

        .header {
            width: auto;
            box-shadow: none;
            border-top: 1px solid #f5f5f9;
            padding: 0 .5rem;
            margin-bottom: .25rem;

            .headerMain {
                height: 1.2rem;
                line-height: 1.2rem;
                border-bottom-width: 0;

                .headerTitle {
                    float: left;
                    width: auto;
                    padding: 0;
                }

                .headerMoney {
                    float: right;
                    width: auto;
                    color: $TEMPLATE2-theme-color;
                    padding: 0;
                }
            }

            .wm {

                li {
                    flex-basis: 50%;
                    border-left: 0;
                    border-top: 1px solid #f5f5f9;
                    text-align: left;
                    padding: .1rem;
                    height: 1rem;
                    padding-top: .2rem;

                    .headerTitle {
                        color: #999999;
                        width: auto;
                        padding-top:0;
                    }

                    .headerMoney {
                        float: right;
                        font-size: .28rem;
                        padding-top:0;
                    }

                    &:nth-child(2n) {
                        border-left: 1px solid #f5f5f9;
                    }

                    &:last-child {
                        border-right:0;
                    }
                }
            }
        }

        .hinder {
            float: none;
            width: auto;
            height: auto;
            box-shadow: none;
            padding: 0 .5rem;

            .hindertext {

                li {
                    border-bottom-color: #f5f5f9;

                    &:first-child {
                        border-top: 1px solid #f5f5f9;
                    }                    

                    .textLine {
                        padding-left: 0;
                        font-size: .36rem;
                    }

                    .money_text {
                        color: #252525;
                    }

                    .rightArrow {
                        filter: grayscale(100%);
                        right: 0;
                        opacity: .7;
                    }
                }
            }

            .hinderMoney {

                .mtField {
                    float: none;
                    width: auto;
                    height: auto;
                    font-size: .36rem;
                    padding-left: 0;
                    margin: .25rem 0;
                    width: 100%;

                    &::placeholder {
                        color:#999999;
                    }
                }
            }
        }

        .hinderButton {
            float: none;
            margin: .25rem .5rem;
            width: -webkit-fill-available;
            height: auto;
            font-size: .3rem;
            padding: .2rem 0;
        }
    }

    aside {

        .inner {
            position: absolute;
            z-index: 12;
            background-color: #ffffff;
            left: .5rem;
            right: .5rem;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 0 0 3px 3px;

            h4 {
                font-size: .36rem;
                font-weight: normal;
                text-align: center;
                color: #ffffff;
                padding: .25rem 0;
                background-color: #DB5C54;
                border-radius: 3px;
            }

            fieldset {
                text-align: center;
                border: 0;
                border-top:1px solid #b0b0b0;
                border-bottom:1px solid #b0b0b0;
                margin:.25rem;
                padding:.25rem 0;

                label {
                    font-size: .36rem;
                    margin-right: .1rem;
                }

                input {
                    height: .64rem;
                    border: 1px solid #B0B0B0;
                    padding: 0 0.25rem;
                    width: 2rem;
                    font-size: .34rem;
                }
            }

            .buttons {
                display: flex;
                padding:.25rem;
                justify-content: space-between;

                a {
                    font-size: .3rem;
                    display: inline-block;
                    padding: .25rem 1.1rem;

                    &.cancel {
                        background-color: #EEEEEE;
                        color: #5F646E;
                    }

                    &.ok {
                        background-color: #DB5C54;
                        color: #ffffff;

                        &.processing {
                            margin: 0 auto;
                        }
                    }
                }
            }
        }

        &.transfering-popup {
            left:0;
            top:0;
            width: 100%;
            height: 100%;
            position: fixed;
            z-index: 11;

            &:before {
                content:'';
                width: 100%;
                height: 100%;
                position: absolute;
                background-color: rgba(0,0,0,.3);
            }
        }
    }
}

.myposition {
    background-color: rgba(239, 239, 239, 1);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
}

.mainText {
    height: 0.66rem;
    padding: 0.2rem 0 0 0.2rem;
    font-size: 0.3rem;
    color: #333333;
}

h6 {
    font-size: .22rem;
    font-weight: normal;
    color: #E83535;
    padding: .2rem;
    padding-bottom: 0;
}

.refresh {
    width: .22rem;
    height: .22rem;
    display: inline-block;
    position: absolute;
    right: .1rem;
    bottom: .1rem;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='22' height='23' viewBox='0 0 22 23'%3E %3Cg transform='scale(.5)' fill='%233985E1'%3E %3Cpath d='M-870.564,1088.811a8.16,8.16,0,0,1-3.644-6.774,8.169,8.169,0,0,1,5.583-7.693l-1.763-2.239a10.862,10.862,0,0,0-6.582,9.989,10.872,10.872,0,0,0,5.289,9.3l-.882,2.009,6.994-.861-3.82-6.258Zm9.579-16.419,1.058-1.952-7.64.746,4.29,5.454,1-1.837a8.128,8.128,0,0,1,4.525,7.291,8.142,8.142,0,0,1-6.641,7.98v.172l1.7,2.181a10.872,10.872,0,0,0,7.7-10.333,10.959,10.959,0,0,0-5.995-9.7Zm0,0' transform='translate(876.966 -1070.436)' /%3E %3C/g%3E %3C/svg%3E");
    background-size: 200% 200%;
    
    &.spin {
        animation: 2s linear infinite normal refresh-spin;
    }
}

@keyframes refresh-spin { from { transform: rotate(0deg); } to { transform: rotate(360deg); }  }

.header {
    background-color: #FFFFFF;
    box-shadow: 0 0 0.2rem #ccc;

    .headerMain {
        overflow: hidden;
        height: 1.3rem;
        border-bottom: 0.01rem solid #D9D9D9;

        .headerTitle {
            float: left;
            width: 100%;
            color: rgba(153, 153, 153, 1);
            font-size: 0.28rem;
            padding: 0.22rem 0 0 0.52rem;
        }

        .headerMoney {
            color: #2D2D2D;
            font-size: 0.4rem;
            padding: 0.22rem 0 0 0.52rem;
        }
    }

    .wm {
        height: 50%;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;

        li {
            text-align: center;
            flex-basis: 25%;
            height: 1.3rem;
            list-style: none;
            border-left: 1px solid #D9D9D9;
            box-sizing: border-box;
            position: relative;

            &:first-child {
                border-left:0;
            }

            &:nth-child(4n+1) {
                border-left: 0;
            }

            &:last-child {
                border-right: 1px solid #d9d9d9;
            }

            &:nth-child(1n+5) {
                border-top: 1px solid #d9d9d9;
            }

            .headerTitle {
                float: left;
                width: 100%;
                color: #2D2D2D;
                font-size: 0.28rem;
                padding: 0.26rem 0 0 0;
            }

            .headerMoney {
                color: #999999;
                font-size: 0.26rem;
                padding: 0.26rem 0 0 0;
            }
        }
    }
}

.hinder {
    float: left;
    width: 7.5rem;
    height: 3.28rem;
    background-color: #FFFFFF;
    box-shadow: 0 0 0.2rem #ccc;

    .hindertext {
        height: 1.8rem;
        width: 100%;

        li {
            float: left;
            width: 100%;
            height: 50%;
            list-style: none;
            border-bottom: 0.01rem solid #D9D9D9;
            position: relative;

            .textLine {
                float: left;
                width: 0.9rem;
                height: 100%;
                padding-left: 0.2rem;
                font-size: 0.36rem;
                line-height: 0.9rem;
                color: #999999;
            }

            .money_text {
                float: left;
                height: 100%;
                padding-left: 0.27rem;
                font-size: 0.28rem;
                color: #2D2D2D;
                line-height: 0.9rem;
            }

            .rightArrow {
                position: absolute;
                right: 0.45rem;
                top: 0.3rem;
                width: 0.18rem;
                height: 0.33rem;
            }
        }
    }

    .hinderMoney {
        width: 100%;
        overflow: hidden;

        .hinderSpan {
            float: left;
            width: 100%;
            padding: 0.15rem 0 0 0.2rem;
            font-size: 0.24rem;
            color: rgba(153, 153, 153, 1);
        }

        .tip {
            float: left;
            padding-left: 0.2rem;
            width: 0.4rem;
            height: 0.59rem;
            font-size: 0.4rem;
        }

        .mtField {
            float: left;
            width: 6.8rem;
            height: 0.59rem;
            border: none;
            font-size: 0.2rem;
            padding-left: 0.1rem;
        }
    }
}

.hinderButton {
    float: left;
    width: 6.9rem;
    height: 0.8rem;
    margin: 0.6rem 0 0 0.3rem;
}
</style>